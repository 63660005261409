import React, { useState } from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';
import satan_blocker from '../assets/images/satan-blocker.png';

const Love = () => {
  const [counter, setCounter] = useState(100);
  //increase counter
  const increase = () => {
    setCounter(count => count + 1);
  };

  //decrease counter
  const decrease = () => {
    setCounter(count => count - 1);
  };

  //reset counter
  const reset = () => {
    setCounter(100);
  };
  return (
    <Layout>
      <Sidebar showTabs="false" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="top"
      >
        <ul>
          <li>
            <h4>Understand Steps of Satan</h4>
            <h6> خطوات الشيطان</h6>
            <ul>
              <li>
                <a href="https://youtu.be/BmdIxlfyx_M">
                  Intorudction - Click here
                </a>
              </li>
            </ul>
          </li>

          <ol>
            <li>
              <>
                <Link to="#stage1">
                  Stages 1-4: Consciousness - commitment - اليقظة - العزم{' '}
                  <span>[9/18/2020]</span>
                </Link>
              </>
            </li>
          </ol>
          <p />
          <h3>References</h3>
          <ul>
            <li>
              <a href="https://youtu.be/mlXxfExscOQ">
                خفف همك وغمك وحزنك وكربك بهذه الطرق الروحية"مواجهة الاكتئاب وعدم
                الرغبة في الحياة{' '}
              </a>
            </li>
          </ul>
        </ul>
      </section>
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="references"
      >
        <p />
        <h3>References</h3>
        <ul>
          <li>
            <a href="https://youtu.be/mlXxfExscOQ">
              خفف همك وغمك وحزنك وكربك بهذه الطرق الروحية"مواجهة الاكتئاب وعدم
              الرغبة في الحياة{' '}
            </a>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default Love;
